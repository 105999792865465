import React, { useEffect, useState } from 'react';
import parse from "html-react-parser";
import Layout from "../layout/Layout";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './job.scss';

export default function JobDetails() {
    const location = useLocation();
    const job = location.state?.job; // Retrieve the job object from the passed state

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        mobileNumber: '',
        cv: null,
        jobcode: job?.id || '',
        location: job?.location || ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    const [isLoading, setIsLoading] = useState(!job);

    useEffect(() => {
        if (job) {
            setIsLoading(false);
        }
    }, [job]);

    // If no job is passed, show an error or redirect
    if (!job) {
        return <div>No job details available</div>;
    }

    // Handle form input change
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Handle file input change
    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            cv: e.target.files[0]
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessages({});
        setSuccessMessage('');

        const formPayload = new FormData();
        formPayload.append('fullName', formData.fullName);
        formPayload.append('email', formData.email);
        formPayload.append('mobileNumber', formData.mobileNumber);
        formPayload.append('cv', formData.cv); // Append the CV file
        formPayload.append('job_id', formData.jobcode); // Append the job ID
        formPayload.append('location', formData.location); // Append the location

        try {
            const response = await axios.post('https://admin.bahwanauto.com/api/v1/apply-job', formPayload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                setSuccessMessage('Job application submitted successfully!');
                setFormData({
                    fullName: '',
                    email: '',
                    mobileNumber: '',
                    cv: null,
                    jobcode: job.id,
                    location: job.location
                });
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                setErrorMessages(error.response.data.errors);
            } else {
                setErrorMessages({ general: 'Failed to submit the job application. Please try again later.' });
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    // If still loading, show a spinner
    if (isLoading) {
        return <div>Loading...</div>; // Show your loading spinner here
    }

    // Render job details once data is loaded
    return (
        <Layout>
            <section className="job" id="job">
                <div className="container">
                    <div className="row">
                        {/* Job Description Section */}
                        <div className="col-lg-7 col-md-12 text">
                            <h1>{job.position}</h1>
                            {parse(job.description || '')} {/* Safely parse HTML content */}
                        </div>
                        
                        {/* Apply Now Section */}
                        <div className="col-lg-4 col-md-12">
                            <div className="card mat-card">
                                <h2 className="mb-4">Apply Now</h2>
                                <p className='mb-1'><span>Department: </span>{job.department}</p>
                                <p className='mb-1'><span>Position: </span>{job.position}</p>
                                <p className='mb-1'><span>Location: </span>{job.location}</p>

                                {/* Application Form */}
                                <form onSubmit={handleSubmit} className="w-100 rounded bg-white">
                                    <div className="row form-group">
                                        <div className="col-lg-12 mt-3">
                                            <input
                                                name="fullName"
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Your Name"
                                                value={formData.fullName}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errorMessages.fullName && <div className="text-danger">{errorMessages.fullName[0]}</div>}
                                        </div>
                                        <div className="col-lg-12 mt-3">
                                            <input
                                                name="email"
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter Your Email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errorMessages.email && <div className="text-danger">{errorMessages.email[0]}</div>}
                                        </div>
                                        <div className="col-lg-12 mt-3">
                                            <input
                                                name="mobileNumber"
                                                type="tel"
                                                className="form-control"
                                                placeholder="0123456789"
                                                value={formData.mobileNumber}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errorMessages.mobileNumber && <div className="text-danger">{errorMessages.mobileNumber[0]}</div>}
                                        </div>
                                        <div className="col-lg-12 mt-3">
                                            <input
                                                name="cv"
                                                accept=".pdf,.doc,.docx"
                                                type="file"
                                                className="form-control-file"
                                                onChange={handleFileChange}
                                                required
                                            />
                                            {errorMessages.cv && <div className="text-danger">{errorMessages.cv[0]}</div>}
                                        </div>
                                        <div className="col-lg-12 mt-3">
                                            <input name="jobcode" value={job.id} type="hidden" />
                                            <input name="location" value={job.location} type="hidden" />
                                        </div>
                                        {errorMessages.general && <div className="text-danger">{errorMessages.general}</div>}
                                        {successMessage && <div className="text-success">{successMessage}</div>}
                                        <div className="mt-3 ml-3">
                                            <button type="submit" className="btn" disabled={isSubmitting}>
                                                {isSubmitting ? 'Submitting...' : 'Apply'}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
