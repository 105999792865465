import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import axios from "axios";
import { base_url } from "../constants";
import LoadingSpinner from "../loader/LoadingSpinner";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

export default function Home() {
  const [banners, setBanners] = useState([]); 
  const [brands, setBrands] = useState([]);
  const [homeData, setHomeData] = useState({});
  const [services, setServices] = useState([]); // Renamed for clarity
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading state
      try {
        const [bannersResponse, homeDataResponse, brandsResponse, servicesResponse] = await Promise.all([
          axios.get("https://admin.bahwanauto.com/api/v1/get_banners"),
          axios.get("https://admin.bahwanauto.com/api/v1/home_data"),
          axios.get("https://admin.bahwanauto.com/api/v1/brandsimages"),
          axios.get("https://admin.bahwanauto.com/api/v1/getServices"),
        ]);
        setBanners(bannersResponse.data);
        setHomeData(homeDataResponse.data);
        setBrands(brandsResponse.data);
        setServices(servicesResponse.data); // Updated state name
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); 
      }
    };
    fetchData();
  }, []); 

  const { title, sub_title, content, button_txt, image } = homeData; // Destructuring for easier access

  return (
    <>
      <Helmet>
        <meta name="title" content={homeData.meta_title || "Welcome to Bahwan Auto"} />
        <meta name="description" content={homeData.meta_description || "Welcome to Bahwan Auto, a leader in automotive and corporate solutions, driven by a legacy of integrity and excellence. Discover our extensive range of services, including authorised Suzuki distribution, advanced car service, auto rental, and leasing, among others."} />
        <meta name="keywords" content={homeData.meta_keywords || "Bahwan Auto, Automotive Solutions, Corporate Solutions, Suzuki Dealership, Car Service, Auto Rental, Automobile Modification, Tyres, Batteries, Lubricants, Industrial Equipment, Auto Parts, Logistics, Warehousing, Sustainable Water Generation, Debt Collection, Pre-owned Cars"} />
        <meta name="author" content={homeData.meta_author || "Bahwan Auto"} />
        <meta name="robots" content={homeData.meta_robots} />
        <meta property="og:title" content={homeData.og_title || "Bahwan Auto - Empowering Journeys, Delivering Excellence"} />
        <meta property="og:description" content={homeData.og_description || "Welcome to Bahwan Auto, a leader in automotive and corporate solutions, driven by a legacy of integrity and excellence. Discover our extensive range of services, including authorised Suzuki distribution, advanced car service, auto rental, and leasing, among others."} />
        <meta property="og:type" content={homeData.og_type || ''} />
        <meta property="og:url" content={homeData.og_url || "https://bahwanauto.com/"} />
        <meta property="og:image" content={homeData.og_image || "https://bahwanauto.com/assets/img/home-02/01.png"} />
        <meta property="og:site_name" content={homeData.og_site_name || "Bahwan Auto"} />
        <meta property="twitter:card" content={homeData.twitter_card || ''} />
        <meta property="twitter:title" content={homeData.twitter_title || "Bahwan Auto - Empowering Journeys, Delivering Excellence"} />
        <meta property="twitter:description" content={homeData.twitter_description || "Welcome to Bahwan Auto, a leader in automotive and corporate solutions, driven by a legacy of integrity and excellence. Discover our extensive range of services, including authorised Suzuki distribution, advanced car service, auto rental, and leasing, among others."} />
        <meta property="twitter:image" content={homeData.twitter_image || "https://bahwanauto.com/assets/img/home-02/01.png"} />
      </Helmet>

      <Layout LoadingSpinner={isLoading}>
        {isLoading ? <LoadingSpinner /> : (
          <div className="homepage">
            {/* Home main sliders section */}
            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel" data-interval="3000">
              <div className="carousel-inner" id="homebanners">
                {banners.map((banner, key) => (
                  <div className={`carousel-item ${key === 0 ? "active" : ""}`} key={key}>
                    <img className="d-block w-100" src={banner.name} alt={`Banner ${key + 1}`} />
                  </div>
                ))}
              </div>
              <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span className="carousel-control-next-icon"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
            {/* End Home main sliders section */}

            <section className="how-we-are padding-top-120 padding-bottom-110">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="common-section-content">
                      <div className="section-title">
                        <h6 className="title both-line uppercase gray">{title}</h6>
                        <h3 className="heading-02">{sub_title}</h3>
                      </div>
                      <div className="paragraph padding-top-20 padding-bottom-25">
                        {content && parse(content)}
                        <Link to="about">
                          <button type="button" className="btn btn-primary mt-1">{button_txt}</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="section-border-image margin-left white-gray">
                      <div className="border-design">
                        <div className="border-bg full-bg"></div>
                        <div className="we-are-img">
                          <img src={image} alt="Service Image" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="h2-service-section padding-50">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="service-section-right white-gray">
                      <div className="section-title">
                        <div className="padding-bottom-15 text-center">
                          <h6 className="title both-line uppercase gray">Our Services</h6>
                        </div>
                        <div className="padding-bottom-30 text-center">
                          <h2 className="heading-02 padding-bottom-35">Discover Our Expertise</h2>
                        </div>
                      </div>
                      <div className="row">
                        {services.map((service, serviceIndex) => (
                          <div className="col-md-4 mb-4 service_div" key={serviceIndex}>
                            <Link to={service.link}>
                              <div className="m-2">
                                <div className="text-center mt-3">
                                  <div className="service_icon_wrapper">
                                    <div className="circle_background" style={{ backgroundColor: service.color_code }}>
                                      <img src={service.image} className="service_icons" alt={service.title} />
                                    </div>
                                  </div>
                                </div>
                                <div className="text-center mt-2">
                                  <span className="service_title title">{service.title}</span>
                                  <p className="text-center no-break">{service && parse(service.content)}</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="certificate-section pt-4 pb-0 pt-sm-5 mb-4 pb-4">
              <div className="auto-container">
                <div className="sec-title text-center">
                  <div className="col-lg-12 text-center">
                    <div className="section-title">
                      <h6 className="title both-line uppercase gray">Our Partners</h6>
                      <div className="padding-15">
                        <h2 className="heading-02">Featured Brands</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="container">
                    <div className="et-icon-box-container column-8 et-make-container" data-gap="8">
                      {brands.map((brand, key) => (
                        <div className="et-make et-icon-box" key={key}>
                          <a className="d-block">
                            <div className="each_brand_category">
                              <div className="icon_container">
                                <img className="img-fluid d-block mx-auto" alt={brand.name} src={brand.image} />
                              </div>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </Layout>
    </>
  );
}