import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import Layout from '../layout/Layout';
import axios from 'axios'; // Axios for API requests

const CareerPage = () => {
  const [jobs, setJobs] = useState([]);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobileNumber: '',
    cv: null,
    job_id: "websie", // Job ID if a specific job is selected
    location: 'website', // Fallback default location
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate(); // Replace useHistory with useNavigate

  // Fetch job vacancies from the API
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('https://admin.bahwanauto.com/api/v1/job-lists');
        setJobs(response.data || []);
      } catch (error) {
        console.error('Error fetching job vacancies', error);
      }
    };

    fetchJobs();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      cv: e.target.files[0], // Handles the file input
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessages({});
    setSuccessMessage('');

    const formPayload = new FormData();
    formPayload.append('fullName', formData.fullName);
    formPayload.append('email', formData.email);
    formPayload.append('mobileNumber', formData.mobileNumber);
    formPayload.append('cv', formData.cv);
    formPayload.append('job_id', formData.job_id); // Add job_id to the payload
    formPayload.append('location', formData.location); // Add location to the payload

    try {
      const response = await axios.post('https://admin.bahwanauto.com/api/v1/apply-job', formPayload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setSuccessMessage('Your application submitted successfully!');
        setFormData({
          fullName: '',
          email: '',
          mobileNumber: '',
          cv: null,
          job_id: 'website',
          location: 'website', // Reset to default location
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setErrorMessages(error.response.data.errors);
      } else {
        setErrorMessages({ general: 'An unexpected error occurred. Please try again.' });
      }
    } finally {
      setIsSubmitting(false);
      // Automatically hide error messages after 30 seconds
      setTimeout(() => setErrorMessages({}), 30000); // 30 seconds timeout
    }
  };

  // Handle "View Description & Apply" button click
  const handleViewJob = (job) => {
    const positionSlug = job.position.replace(/\s+/g, '-');
    navigate(`/job-description/${positionSlug}`, { state: { job } });
  };

  return (
    <Layout>
      <div className="container padding-bottom-10" style={{ minHeight: '100vh' }}>
        <div className="row w-100 justify-content-center">
          {/* Display Job Vacancies if available */}
          {jobs.length > 0 ? (
            <section className="job" id="job">
              <h6 className="text-center mb-3">Job Opportunities</h6>
              <div className="ml-auto mr-auto">
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th>SNO</th>
                        <th>Department</th>
                        <th>Position</th>
                        <th>Vacancies</th>
                        <th>Location</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobs.map((job, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{job.department}</td>
                          <td>{job.position}</td>
                          <td>{job.no_of_vacancy}</td>
                          <td>{job.location}</td>
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() => handleViewJob(job)}
                            >
                              View Description & Apply
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          ) : (
            <>
              <div className="col-md-4 mx-auto padding-top-125">
              <h3 className="text-center">Submit Your Application</h3>

                <form onSubmit={handleSubmit} encType="multipart/form-data" className="border">
                  <div className="m-5">
                    {/* Full Name Field */}
                    <label className="d-block mb-4">
                      <span className="d-block mb-2">Your name</span>
                      <input
                        required
                        name="fullName"
                        type="text"
                        className="form-control"
                        value={formData.fullName}
                        onChange={handleChange}
                      />
                      {errorMessages.fullName && <div className="text-danger mt-1">{errorMessages.fullName[0]}</div>}
                    </label>

                    {/* Email Field */}
                    <label className="d-block mb-4">
                      <span className="d-block mb-2">Email address</span>
                      <input
                        required
                        name="email"
                        type="email"
                        className="form-control"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errorMessages.email && <div className="text-danger mt-1">{errorMessages.email[0]}</div>}
                    </label>

                    {/* Phone Number Field */}
                    <label className="d-block mb-4">
                      <span className="d-block mb-2">Phone number</span>
                      <input
                        required
                        name="mobileNumber"
                        type="text"
                        className="form-control"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                      />
                      {errorMessages.mobileNumber && <div className="text-danger mt-1">{errorMessages.mobileNumber[0]}</div>}
                    </label>

                    {/* CV Upload */}
                    <div className="mb-4">
                      <label className="d-block mb-2">Your CV</label>
                      <div className="form-control h-auto">
                        <input
                          required
                          name="cv"
                          type="file"
                          className="form-control-file"
                          onChange={handleFileChange}
                        />
                      </div>
                      {errorMessages.cv && <div className="text-danger mt-1">{errorMessages.cv[0]}</div>}
                    </div>

                    {/* Hidden Fields */}
                    <input name="job_id" value={formData.job_id || 'website'} type="hidden" />
                    <input name="location" value={formData.location} type="hidden" />

                    {/* General Error Message */}
                    {errorMessages.general && <div className="text-danger mb-3">{errorMessages.general}</div>}
                    {successMessage && <div className="text-success mb-3">{successMessage}</div>}

                    {/* Submit Button */}
                    <div className="mb-3 text-center">
                      <button type="submit" className="btn btn-primary px-3" disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Apply'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CareerPage;
