import React, { Component } from "react";
import { GoogleApiWrapper, Map, InfoWindow, Marker } from "google-maps-react";

const A = ({ markerData }) =>{ 
  return(
<div className="map-marker"><p>{markerData}</p></div>)};

export default function Testing({ data }) {
  return <MapComponent data={data} />;
}

export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeComponent: null,
    activeMarker: null,
    selectedPlace: null,
    markers: this.props.data,
    final: {
      lat: 24.7863537118667,
      lng: 55.60774482484828,
    },
    zoom:8
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        showingInfoWindow: false,
        activeMarker:{},
        zoom:8
      });
    }
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
      activeComponent: <A markerData={props.name} />,
    });
  };

  onMapClicked = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        selectedPlace: null,
      });
    }
  };

  onSidebarItemClick = (props,marker) => {
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
      activeComponent: <A markerData={marker.name || marker.address} />,
      final: {
        lat: marker.latitude,
        lng: marker.longitude,
      },
      zoom:10
    });
  };

  render() {
    const { activeMarker, showingInfoWindow, final } = this.state;

    return (
      <div className="store-locator-container">
        <div className="sidebar">
          <div className="sidebar-header text-center">
            <p>{this.props.data.length < 1 && "Please select brand and emirate"}</p>
          </div>
          {this.props.data.map((marker, key) => (
            <div
              key={key}
              className={`address-item ${activeMarker === marker ? "active" : ""}`}
              onClick={(props) => {
                this.onSidebarItemClick(props, marker);
              }}
            >
              
              <div>
                <h6 className="ml-4 mt-2">{key+1}.{marker.name}</h6>
                <p className="ml-4 main-address"><span className="font-weight-bold">Address :</span> {marker.address}</p>
                <p className="ml-4 main-address"><span className="font-weight-bold">City : </span> {marker.city}</p>
                <p className="ml-4 main-address"><span className="font-weight-bold">Phone : </span> {marker.phone}</p>
                <p className="ml-4 main-address"><span className="font-weight-bold">Working : </span>{marker.hours}</p>
                <p className="ml-4 main-address"><span className="font-weight-bold">Off day : </span>{marker.off_day}</p>
              </div> 
            </div>
          ))}
        </div>

        <div className="map">
          <Map
            google={this.props.google}
            zoom={this.state.zoom}
            initialCenter={final}
            center={final}
            onClick={this.onMapClicked}
          >
            {this.props.data.map((marker, markerIndex) => (
              <Marker
                key={markerIndex}
                onClick={(props, marker) => {
                  this.onMarkerClick(props, marker);
                }}
                position={{ lat: marker.latitude, lng: marker.longitude }}
                name={marker.address}
              />
            ))}
            {activeMarker && showingInfoWindow && (
              <InfoWindow
                marker={activeMarker}
                visible={showingInfoWindow}
              >
                {this.state.activeComponent}
              </InfoWindow>
            )}
          </Map>
        </div>
      </div>
    );
  }
}



const MapComponent = GoogleApiWrapper({
  apiKey: "AIzaSyABixw1LlXT9gBcl7iMN3oc52shTkCCBtY",
})(MapContainer);
