import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import { Link, useLocation } from "react-router-dom";
import parse from "html-react-parser";

import { useState } from "react";
import "react-image-lightbox/style.css";
import axios from "axios";
import { base_url } from "../constants";
import "./brands.scss";
import LoadingSpinner from "../loader/LoadingSpinner";

export default function Brands() {
  const icons = [
    {
      img: "/assets/img/sticky_side/book.png",
      ref: "book-appointment",
      span: "Request Quote",
    },
    {
      img: "/assets/img/sticky_side/calender.png",
      ref: "genuine-parts",
      span: "Book Service",
    },
    {
      img: "/assets/img/sticky_side/download.png",
      ref: "book-appointment",
      span: "Download Brochure",
    },
    {
      img: "/assets/img/sticky_side/setting.png",
      ref: "genuine-parts",
      span: "genuine parts",
    },
    {
      img: "/assets/img/sticky_side/view.jpg",
      ref: "book-appointment",
      span: "360 view",
    },
  ];

  const location = useLocation();
  const user_page = location.pathname.match(/([^\/]*)\/*$/)[1];
  const [brandDetails, setBrandDetails] = useState();
  const [featuredVehicles, setFeaturedVehicles] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [brands, setBrands] = useState([]);

  function convertArray(data) {
    var services = data.replace(/'/g, '"'); //replacing all ' with "
    services = JSON.parse(services);
    return services;
  }
  useEffect(() => {
    setIsLoading(true);
    axios
      .get("https://admin.bahwanauto.com/api/v1/brandDetails/" + user_page)
      .then((res) => {
        setBrandDetails(res.data);
        // setFeatures_destrucre(res.data.features.split('*'))
        setIsLoading(false);
      });

    axios
      .get("https://admin.bahwanauto.com/api/v1/featuredVehicles")
      .then((response) => {
        setIsLoading(false);
        setFeaturedVehicles(response.data);
      });

    if (user_page == "Other%20Brands") {
      axios
        .get("https://admin.bahwanauto.com/api/v1/vehicle_brands_logos")
        .then((response) => {
          setIsLoading(false);
          setBrands(response.data);
        });
    }
  }, []);

  return (
    <Layout LoadingSpinner={isLoading}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner" id="homebanners">
              {brandDetails &&
                convertArray(brandDetails.images).map((banner, key) => {
                  return (
                    <div
                      key={key}
                      className={`carousel-item ${key == 0 ? "active" : ""}`}
                    >
                      <img
                        className="d-block w-100"
                        src={"https://admin.bahwanauto.com/" + banner}
                      />
                    </div>
                  );
                })}
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span className="carousel-control-prev-icon"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
            >
              <span className="carousel-control-next-icon"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>

          {user_page == "Other%20Brands" ? (
            <section className="about-story-section padding-top-50 container">
              <div className="section-title">
                <h2 className="heading-02">
                  {brandDetails && brandDetails.name}
                </h2>
              </div>
            </section>
          ) : (
            <section className="about-story-section padding-top-50 padding-bottom-100">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title padding-bottom-10">
                      <h2 className="heading-02">
                        {brandDetails && brandDetails.name}
                      </h2>
                    </div>
                    <div className="paragraph padding-top-20">
                      {brandDetails && parse(brandDetails.content)}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          <div className="row container-fluid">
            {featuredVehicles &&
              user_page == "Suzuki" &&
              featuredVehicles.map((f_vehicle, index) => {
                return (
                  <div className="col-md-3 mb-5" key={index}>
                    {/* <Link to={"/explore/" + f_vehicle.id}> */}
                    <div className="card">
                      <div className="m-3">
                        <div className="items-head">
                          <img
                            src={base_url + f_vehicle.main_image}
                            alt="img"
                          />
                        </div>
                        <div className="items-body text-center mt-3">
                          <h5 className="heading-05">{f_vehicle.name}</h5>
                        </div>
                      </div>
                    </div>
                    {/* </Link> */}
                  </div>
                );
              })}
          </div>

          {user_page == "Other%20Brands" && (
            <section className="certificate-section pt-4 pb-0 pt-sm-5">
              <div className="auto-container">
                <div className="sec-title text-center">
                  <div className="col-lg-12 text-center">
                    <div className="section-title">
                      {/* <h6 className="title both-line uppercase gray">
                    Our Brands
                  </h6> */}
                      {/* <div className="padding-15">
                    <h2 className="heading-02">Vehicle Brands</h2>
                  </div> */}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <div
                      className="et-icon-box-container column-8 et-make-container"
                      data-gap="8"
                    >
                      {brands
                        ? brands.map((brand, key) => {
                            return (
                              <div className="et-make et-icon-box" key={key}>
                                <a className="d-block">
                                  <div className="each_brand_category">
                                    <div className="icon_container">
                                      <Link
                                        to={brand.link !== "none" && brand.link}
                                      >
                                        <img
                                          className="img-fluid d-block mx-auto"
                                          alt="Nissan"
                                          src={base_url + brand.image}
                                        />
                                      </Link>
                                    </div>
                                    <div className="text_container">
                                      <h3>Title</h3>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </Layout>
  );
}
