import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row, Container, Modal } from "react-bootstrap";
import Layout from "../layout/Layout";
import LoadingSpinner from "../loader/LoadingSpinner";
import "./media.scss";

function Videos() {
  const [category, setCategory] = useState([]);
  const [videosAll, setVideosAll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeMenu, setActiveMenu] = useState("all");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getVideos("All");
    axios.get("https://admin.bahwanauto.com/api/v1/categories_list").then((res) => {
      setCategory(res.data);
      setIsLoading(false);
    });
  }, []);

  const getVideos = (cat) => {
    setIsLoading(true);
    axios
      .get("https://admin.bahwanauto.com/api/v1/media_by_videos/" + cat)
      .then((res) => {
        setVideosAll(res.data);
        setIsLoading(false);
      });
  };

  const handleImageClick = (video) => {
    setSelectedVideo(video);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedVideo(null);
    setShowModal(false);
  };

  return (
    <Layout LoadingSpinner={isLoading}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Container fluid className="mt-5 pt-5 gallery-container">
          <Row className="Feaild">
            <Col
              lg={2}
              md={3}
              xs={12}
              sm={12}
              onClick={() => {
                getVideos("All");
                setActiveMenu("all");
              }}
            >
              <span
                className={
                  activeMenu === "all" ? "mt-2 showall Active" : "mt-2 showall"
                }
              >
                Show All
              </span>
            </Col>
            {category.map((element, index) => (
              <Col
                lg={2}
                md={3}
                xs={12}
                sm={12}
                key={index}
                onClick={() => {
                  getVideos(element.name);
                  setActiveMenu(index);
                }}
              >
                <span
                  className={activeMenu === index ? "titles Active" : "titles"}
                >
                  {element.name}
                </span>
              </Col>
            ))}
          </Row>

          <Row className="images-row mb-5">
            {videosAll.length > 0 ? (
              videosAll.map((video, index) => (
                <Col lg={3} key={index}>
                  <img
                    src={"https://admin.bahwanauto.com/" + video.thumbnail}
                    onClick={() => handleImageClick(video)}
                    alt=""
                  />
                </Col>
              ))
            ) : (
              <div className="mx-auto padding-top-80">
                <span className="text-center">
                  No videos found in this category
                </span>
              </div>
            )}
          </Row>

          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Body>
              {selectedVideo && (
                <iframe
                  src={`https://www.youtube.com/embed/${selectedVideo.video_link}`}
                  title={selectedVideo.title}
                  width="100%"
                  height="500"
                  allowFullScreen
                ></iframe>
              )}
            </Modal.Body>
          </Modal>
        </Container>
      )}
    </Layout>
  );
}

export default Videos;
