import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Parser from "html-react-parser";
import LoadingSpinner from "../loader/LoadingSpinner";
import Layout from "../layout/Layout";
import './news.scss'
function News() {
    const [news, setNews] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get("https://admin.bahwanauto.com/api/v1/news_list")
            .then((response) => {
                setNews(response.data);
                setIsLoading(false);
            });
    }, [0]);
    return (
        <Layout LoadingSpinner={isLoading}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="news">

                    <section className="details-card">
                        <div className="container">
                        <h3 className="heading-02 mt-5 mb-5 text-center"> Press Releases </h3>

                            <div className="row">

                                {news &&
                                    news.map((element, index) => {
                                        return (
                                            <div className="col-md-4" key={index}>
                                                <div className="card-content">
                                                    <div className="card-img">
                                                        <img src={
                                                            "https://admin.bahwanauto.com/" +
                                                            JSON.parse(element.images)[0]
                                                        } alt="" />
                                                        {/* <span><h4>{element.title}</h4></span> */}
                                                    </div>
                                                    <div className="card-desc">
                                                        <h6>{element.sub_title}</h6>
                                                        {Parser(element.content.substring(0, 100))}
                                                        <div className="text-center readmore">
                                                            <Link to={"" + element.id} className="btn-card ">Read</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </section>
                </div>


            )}
        </Layout>
    );
}

export default News;
