import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { Helmet } from "react-helmet";
import axios from "axios";
import parse from "html-react-parser";
import LoadingSpinner from "../loader/LoadingSpinner";

// New reusable component for title and content
const TitleContentCard = ({ title, content }) => (
  <div className="col-md-6">
    <div className="card">
      <div className="content">
        <h5>{title}</h5>
        {parse(content)}
      </div>
    </div>
  </div>
);

export default function About() {
  const [aboutData, setAboutData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [aboutResponse] = await Promise.all([
          axios.get("https://admin.bahwanauto.com/api/v1/about_data"),
        ]);
        setAboutData(aboutResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Layout LoadingSpinner={isLoading}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Helmet>
            <meta name="title" content={aboutData.meta_title || "Welcome to Bahwan Auto"} />
            <meta name="description" content={aboutData.meta_description || "Welcome to Bahwan Auto, a leader in automotive and corporate solutions, driven by a legacy of integrity and excellence. Discover our extensive range of services, including authorised Suzuki distribution, advanced car service, auto rental, and leasing, among others."} />
            <meta name="keywords" content={aboutData.meta_keywords || "Bahwan Auto, Automotive Solutions, Corporate Solutions, Suzuki Dealership, Car Service, Auto Rental, Automobile Modification, Tyres, Batteries, Lubricants, Industrial Equipment, Auto Parts, Logistics, Warehousing, Sustainable Water Generation, Debt Collection, Pre-owned Cars"} />
            <meta name="author" content={aboutData.meta_author || "Bahwan Auto"} />
            <meta name="robots" content={aboutData.meta_robots} />
            <meta property="og:title" content={aboutData.og_title || "Bahwan Auto - Empowering Journeys, Delivering Excellence"} />
            <meta property="og:description" content={aboutData.og_description || "Welcome to Bahwan Auto, a leader in automotive and corporate solutions, driven by a legacy of integrity and excellence. Discover our extensive range of services, including authorised Suzuki distribution, advanced car service, auto rental, and leasing, among others."} />
            <meta property="og:type" content={aboutData.og_type || ''} />
            <meta property="og:url" content={aboutData.og_url || "https://bahwanauto.com/"} />
            <meta property="og:image" content={aboutData.og_image || "https://bahwanauto.com/assets/img/home-02/01.png"} />
            <meta property="og:site_name" content={aboutData.og_site_name || "Bahwan Auto"} />
            <meta property="twitter:card" content={aboutData.twitter_card || ''} />
            <meta property="twitter:title" content={aboutData.twitter_title || "Bahwan Auto - Empowering Journeys, Delivering Excellence"} />
            <meta property="twitter:description" content={aboutData.twitter_description || "Welcome to Bahwan Auto, a leader in automotive and corporate solutions, driven by a legacy of integrity and excellence. Discover our extensive range of services, including authorised Suzuki distribution, advanced car service, auto rental, and leasing, among others."} />
            <meta property="twitter:image" content={aboutData.twitter_image || "https://bahwanauto.com/assets/img/home-02/01.png"} />
          </Helmet>
          <section className="about-story-section padding-top-105">
            <div className="">
              <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src={aboutData.image} alt="About Us" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="about-story-section padding-top-50 padding-bottom-100">
            <div className="container">
              <div className="row">
                <div className="container mt-2">
                  <section id="about-us" className="mb-5">
                    {aboutData && parse(aboutData.content)}
                  </section>
                </div>

                <div className="col-lg-12">
                  <div className="paragraph">
                    <div className="row vision-mission-values">

                      <div class="col-md-6">
                        <div class="breadcrumb-area mission">
                          <div class="container">
                              <div class="row">
                                <div class="col-lg-12">
                                    <div class="breadcrumb-inner text-center">
                                      <h5 class="page-title">{aboutData.vision_title}</h5>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="content">
                          {parse(aboutData.vision_content)}
                          </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="breadcrumb-area mission">
                          <div class="container">
                              <div class="row">
                                <div class="col-lg-12">
                                    <div class="breadcrumb-inner text-center">
                                      <h5 class="page-title">{aboutData.mission_title}</h5>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="content">
                          {parse(aboutData.mission_content)} 
                          </div>
                        </div>
                    </div>


                    </div>

                    <div className="breadcrumb-area mission">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="breadcrumb-inner text-center">
                              <h5 className="page-title">{aboutData.values_title}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row vision-mission">
                      <TitleContentCard title={aboutData.values_s_one_title} content={aboutData.values_s_one_content} />
                      <TitleContentCard title={aboutData.values_s_two_title} content={aboutData.values_s_two_content} />
                      <TitleContentCard title={aboutData.values_s_three_title} content={aboutData.values_s_three_content} />
                      <TitleContentCard title={aboutData.values_s_four_title} content={aboutData.values_s_four_content} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </Layout>
  );
}