import React, { useEffect, useState } from "react";
import LoadingSpinner from "../loader/LoadingSpinner";
import Layout from "../layout/Layout";
import axios from "axios";
import Parser from "html-react-parser";
import { useLocation } from "react-router-dom";

export default function Staticpage() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const url = location.pathname;
    const page_name = url.split("/")[1]; 
    
    setIsLoading(true);
    axios
      .get(`https://admin.bahwanauto.com/api/v1/single_page/${page_name}`)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, [location]);

  return (
    <Layout LoadingSpinner={isLoading}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="news">
          <section className="details-card">
            <div className="container">
              <h3 className="heading-02 mt-5 mb-5 text-center">
                {data && data.title_en}
              </h3>
              {data && Parser(data.content_en)}
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}
