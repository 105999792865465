import axios from "axios";
import React, { useEffect, useState } from "react";
import { Carousel, Tabs, Tab, Col, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Search } from "react-bootstrap-icons";
import "./media.scss";
import Layout from "../layout/Layout";
import LoadingSpinner from "../loader/LoadingSpinner";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Helmet } from "react-helmet";

function Images() {
  const [category, setCategory] = useState();
  const [imagesAll, setImagesAll] = useState();
  const [activeMenu, setActiveMenu] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [lightboxsubIndex, setLightboxsubIndex] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    getImages("All");
    axios.get("https://admin.bahwanauto.com/api/v1/categories_list").then((res) => {
      setCategory(res.data);
      setIsLoading(false);
    });
  }, []); // Changed [0] to []

  const getImages = (cat) => {
    setIsLoading(true);
    axios
      .get("https://admin.bahwanauto.com/api/v1/media_by_images/" + cat)
      .then((res) => {
        setImagesAll(res.data);
        setIsLoading(false);
      });
  };

  // light box

  const handleShow = (index, subIndex) => {
    setLightboxOpen(true);
    setLightboxIndex(index);
    setLightboxsubIndex(subIndex);
  };

  const handleClose = () => {
    setLightboxOpen(false);
  };

  return (
    <Layout LoadingSpinner={isLoading}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Container fluid className="mt-5 pt-5 gallery-container">
          <Helmet>
            <meta name="title" content="Gallery - BAT" />
            <meta name="description" content="Explore the gallery of Bahwan Automobiles and Trading (BAT), showcasing our automotive expertise, premium car modifications, and industrial equipment solutions." />
            <meta name="keywords" content="BAT Gallery, Bahwan Automobiles Gallery, Automotive Gallery, Car Modifications, Industrial Equipment Gallery" />
            <meta name="author" content="Bahwan Automobiles and Trading (BAT)" />
            <meta name="robots" content="index, follow" />
            <meta property="og:title" content="Gallery - BAT" />
            <meta property="og:description" content="Explore the gallery of Bahwan Automobiles and Trading (BAT), showcasing our automotive expertise, premium car modifications, and industrial equipment solutions." />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.bahwanauto.com/images" />
            <meta property="og:image" content="https://localhost:8000/storage/uploads/b5TPlzoK5YCHM1Bc1acVfuQiKrO2RgOJbOyk5HfT.jpg" />
            <meta property="og:site_name" content="Bahwan Automobiles and Trading (BAT)" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content="Gallery - BAT" />
            <meta property="twitter:description" content="Explore the gallery of Bahwan Automobiles and Trading (BAT), showcasing our automotive expertise, premium car modifications, and industrial equipment solutions." />
            <meta property="twitter:image" content="https://localhost:8000/storage/uploads/b5TPlzoK5YCHM1Bc1acVfuQiKrO2RgOJbOyk5HfT.jpg" />
          </Helmet>
          <Row className="Feaild">
            <Col lg={5}>
              <Row>
                <Col
                  lg={6}
                  md={2}
                  xs={12}
                  sm={12}
                  onClick={() => {
                    getImages("All");
                    setActiveMenu("all");
                  }}
                  style={{ color: 'darkblue', display: 'flex', justifyContent: "end", alignItems: "center" }}
                >
                  <span
                    className={activeMenu === "all" ? " showall Active" : "showall"}
                  >
                    Show All
                  </span>
                </Col>
                <Col lg={6}>
                  {category &&
                    category.map((element, index) => {
                      return (
                        <button
                          style={{ color: 'darkblue' }}
                          className="btn"
                          key={index}
                          onClick={() => {
                            getImages(element.name);
                            setActiveMenu(index);
                          }}
                        >
                          <span
                            className={activeMenu === index ? "titles Active" : "titles"}
                          >
                            {element.name}
                          </span>
                        </button>
                      );
                    })}
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="container">
            <Row className="images-row mb-5">
              {imagesAll && imagesAll.length > 0 ? (
                imagesAll.map((finalImage, subIndex) => (
                  <Col lg={4} key={subIndex} className="image-gallery">
                    <figure className="hover-img" onClick={() => handleShow(lightboxIndex, subIndex)}> {/* Changed index to lightboxIndex */}
                      <img
                        src={finalImage.image}
                        className="img-fluid"
                        alt=""
                      />
                      <figcaption>
                        <Search color="white" />
                      </figcaption>
                    </figure>
                  </Col>
                ))
              ) : (
                <div className="mx-auto padding-top-80">
                  <span className="text-center">
                    No images found in this category
                  </span>
                </div>
              )}
            </Row>
          </div>

          {lightboxOpen && (
  <Lightbox
    mainSrc={imagesAll[lightboxIndex].image} // Correctly using the image URL
    onCloseRequest={handleClose}
    nextSrc={
      imagesAll[(lightboxIndex + 1) % imagesAll.length].image // Correctly using the next image URL
    }
    prevSrc={
      imagesAll[(lightboxIndex + imagesAll.length - 1) % imagesAll.length].image // Correctly using the previous image URL
    }
    onMovePrevRequest={() =>
      setLightboxIndex((lightboxIndex + imagesAll.length - 1) % imagesAll.length) // Update to move to the previous image
    }
    onMoveNextRequest={() =>
      setLightboxIndex((lightboxIndex + 1) % imagesAll.length) // Update to move to the next image
    }
  />
)}
        </Container>
      )}
    </Layout>
  );
}

export default Images;