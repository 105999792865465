import Navigation from './Navigation'
import Footer from './Footer'
import "./layout.css"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Scroll_Top from '../scroll-top/Scroll_Top';

const Layout = ({ children, LoadingSpinner }) => {
  return (
    <>
      <Scroll_Top />
      <Navigation />
      <ToastContainer />
      <main className='layout'>{children}</main>
      {!LoadingSpinner&&
      <Footer />
      }
    </>
  )
}

export default Layout
