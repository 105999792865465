import React, { useEffect, useState } from 'react'
import "./explore.scss"
import Layout from '../layout/Layout'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { base_url } from '../constants'

export default function Explore() {


    const [pageData, setPageData] = useState()
    const location = useLocation()
    const user_page = location.pathname.match(/([^\/]*)\/*$/)[1]

    useEffect(() => {
        axios.get("https://admin.bahwanauto.com/api/v1/getFeaturedVehiclesById/" + user_page).then((response) => {
            setPageData(response.data)
        });
    }, [])

    function parseJson(data) {
        return JSON.parse(data)
    }
    return (
        <Layout>
            <>



                {/* <section className="icons" id="icons">
                    <ul id="MiniLeftNav">
                        <li>
                            <a className="nav-text" href="{{icons.ref}}" target="_self">
                                <img src="{{imageurl+icons.img}}" width="22px" height="22px" alt="" srcset="" />
                                <span>icon link</span>
                            </a>
                        </li>
                    </ul>
                </section> */}
                {pageData &&
                    <section className="models mt-5" id="model">

                        <div className="padding-top-5">
                    <img src={base_url+pageData.images}/>
                </div>


                        {/* <iframe width="100%" src={pageData.video} scrolling="no" title="(model_details.title)" className="w-full  xl:h-screen"></iframe> */}

                        <div className='mt-5'>


                            <h3 className='text-capitalize'><span>{pageData.name}</span> models</h3>

                            <div className="container spec" >
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">gl</th>
                                                    <th scope="col">glx</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {JSON.parse(pageData.specifications).map((spec, specIndex) => {
                                                    return (
                                                        <tr key={specIndex}>
                                                            <td className="h5 pt-3">{spec.name}</td>
                                                            <td>{spec.gl}</td>
                                                            <td>{spec.glx}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-lg-1"></div>
                                </div>
                            </div>

                            <div className="container">
                                <hr />
                                <div className="mt-4 description" >
                                    {JSON.parse(pageData.description).map((desc, descIndex) => {
                                        return (
                                            <>
                                                {descIndex % 2 == 0 ?
                                                    <div className="row" key={descIndex}>
                                                        <div className="col-lg-6">
                                                            <h6>{desc.title}</h6>
                                                            <p>{desc.text}</p>
                                                        </div>
                                                        <div className="col-lg-6">

                                                            <div className="section-border-image margin-left white-gray">
                                                                <div className="border-design">
                                                                    <div className="border-bg full-bg"></div>
                                                                    <div className="we-are-img">
                                                                        <img src={base_url + desc.image} alt="" srcset="" className="w-100" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>

                                                    :
                                                    <div className="mt-4 pt-4">
                                                        <div className="row mt-4 pt-4">
                                                            <div className="col-lg-6">

                                                                <div className="section-border-image margin-right white-gray">
                                                                    <div className="border-design">
                                                                        <div className="border-bg full-bg"></div>
                                                                        <div className="we-are-img">
                                                                            <img src={base_url + desc.image} alt="" srcset="" className="w-100" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6">
                                                                <h6>{desc.title}</h6>
                                                                <p>{desc.text}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )
                                    })}
                                </div>
                                

                                {JSON.parse(pageData.desc).map((desc_one, descIndex) => {
                                    return (
                                        <div className="mt-3 mb-3" key={descIndex}>
                                            <h6>{desc_one.title}</h6>
                                            <p>{desc_one.text}</p>
                                        </div>
                                    )
                                })}
                                <div className="row seperate mt-4 pt-3 pb-3 mb-4">


                                    <div className="col-lg-6">

                                        <div className="section-border-image margin-right white-gray">
                                            <div className="border-design">
                                                <div className="border-bg full-bg"></div>
                                                <div className="we-are-img">
                                                    <img src={base_url + JSON.parse(pageData.seperation).image} alt="" srcset="" className="w-100" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <h6>{JSON.parse(pageData.seperation).title}</h6>
                                        <p>{JSON.parse(pageData.seperation).text}</p>
                                    </div>
                                </div>
                                <div className="mt-3 mb-3">
                                    <h4>features</h4>
                                    {JSON.parse(pageData.features).map((feature, featureIndex) => {
                                        return (
                                            <div key={featureIndex}>
                                                <h6>{feature.title}</h6>
                                                <p>{feature.text}</p>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className="row mt-4 mb-4">
                                    {JSON.parse(pageData.featureimages).map((featureImage, featureImageIndex) => {
                                        return (
                                            <div className="col-lg-3 mt-4" key={featureImageIndex}>
                                                <img src={base_url + featureImage} alt="" srcset="" className="w-100" c />
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                        </div>


                    </section>
                }



                

            </>
        </Layout>
    )
}
