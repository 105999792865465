import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import Layout from "../layout/Layout";
import axios from "axios";
import LoadingSpinner from "../loader/LoadingSpinner";

export default function DynamicPages() {
  const [isLoading, setIsLoading] = useState(true);
  const [pages, setPages] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const userPage = location.pathname.split("/").filter(Boolean).pop();

  useEffect(() => {
    // Remove trailing slash if exists
    if (location.pathname.endsWith('/')) {
      navigate(location.pathname.slice(0, -1), { replace: true });
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://admin.bahwanauto.com/api/v1/single_dynamic_pages/${userPage}`
        );
        setPages(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userPage, location, navigate]);

  return (
    <Layout>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <LoadingSpinner />
        </div>
      ) : (
        <section className="about-story-section padding-top-105 padding-bottom-100">
          <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              {pages?.photos?.map((image, key) => (
                <div key={key} className={`carousel-item ${key === 0 ? "active" : ""}`}>
                  <img className="d-block w-100" src={image.path} alt={`Slide ${key}`} />
                </div>
              ))}
            </div>
            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
          <div className="container">
            <div className="section-title padding-bottom-10 padding-top-50">
              <h2 className="heading-02">{pages?.title}</h2>
            </div>
            <div className="paragraph padding-top-20">
              <div className="padding-bottom-15">{pages?.content && parse(pages.content)}</div>
              {pages?.is_link_active == 1 && (
                <a href={pages.external_link} target="_blank" rel="noopener noreferrer" className="padding-top-30 mt-5">
                  <button className="btn btn-primary">
                    For more information <span className="font-weight-bold">click here</span>
                  </button>
                </a>
              )}
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
}
